import { createUserWithEmailAndPassword, getAuth } from "firebase/auth"
import { doc, setDoc } from "firebase/firestore"
import { useState } from "react"
import { Alert, Container, Form } from "react-bootstrap"
import { db } from "../../Firebase/FirebaseConfig"
import NavBar from "../general/NavBar"
import { RandomPassword } from "./RandomPassword"

const AddAdmin = () => {
    const [itemSeleccionado, setitemSeleccionado] = useState({
        name: "",
        email: "",
        type: "",
        action: "",
        cardNumberId: ""
    })

    const HandleChange = e => {
        const { name, value } = e.target
        setitemSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(itemSeleccionado)
    }


    const PostAdmin = () => {
        const auth = getAuth();
        const temPass = RandomPassword();
        createUserWithEmailAndPassword(auth, itemSeleccionado.email, temPass)
            .then((userCredential) => {
                // Signed in 
                console.log(userCredential);
                const user = userCredential.user.uid;
                document.getElementById("email").innerHTML = itemSeleccionado.email;
                document.getElementById("password").innerHTML = temPass;
                PostAdmin2(userCredential.user.uid);
            })
            .catch((error) => {
                // const errorCode = error.code;
                // const errorMessage = error.message;
                document.getElementById("email").innerHTML = "error";
                document.getElementById("password").innerHTML = "error";
            });
    }
    const PostAdmin2 = async (id) => {
        await setDoc(doc(db, "admins", id), {
            name: itemSeleccionado.name,
            type: itemSeleccionado.type,
            action: itemSeleccionado.action,
            cardNumberId: itemSeleccionado.cardNumberId
        });
        alert("Admin created!");
    }
    return (
        <div style={{
            backgroundImage: "url(https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80) ",
            backgroundSize: "cover",
            height: "120vh"
        }}>
            <Container className="text-white">
                <h3 className="text-center pt-5">New Admin</h3>
                <br />
                <div className="form-group">
                    <label className="form-label">Name</label>

                    <input className="form-control mb-3" type="text" name="name" onChange={HandleChange}></input>

                    <label className="form-label">Email</label>

                    <input className="form-control mb-3" type="email" name="email" onChange={HandleChange}></input>

                    <label className="form-label">Action</label>

                    <input className="form-control mb-3" type="text" name="action" onChange={HandleChange}></input>

                    <label className="form-label">Type</label>
                    <Form.Select aria-label="type" name="type" className="mb-3" onChange={HandleChange}>
                        <option value="0" >Selec a type</option>
                        <option value="simple" >simple</option>
                        <option value="middle" >middle</option>
                        <option value="super" >super</option>
                    </Form.Select>

                    <label className="form-label">Card Number ID (700 or more)</label>

                    <input className="form-control mb-3" type="number" name="cardNumberId" onChange={HandleChange}></input>
                    <br />
                    <button onClick={() => PostAdmin()} className="btn btn-primary w-100">Add</button>

                    <Alert className="my-5 text-center">
                        <p>Email:<br /><span id="email"></span></p>
                        <p>password: <br /><span id="password"></span></p>
                    </Alert>
                </div>
            </Container>
        </div>
    )

}

export default AddAdmin