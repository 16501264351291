import React, { useContext, useState } from "react"
import { Alert, Card, Form } from "react-bootstrap"
import UserContext from "../../context/UserContext"
import logo from '../../assets/logo85.png'
const LoginComponent = () => {

    const [datosIngresados, setDatosIngresados] = useState({
        user: "",
        password: ""
    })

    const HandleChange = e => {
        const { name, value } = e.target
        setDatosIngresados((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(datosIngresados)
    }
    // const auth = getAuth();

    const { Login, loginError } = useContext(UserContext)

    const ClickLogin = () => {

        Login(datosIngresados.user, datosIngresados.password)

    }
    return (
        <>

            <Card style={{ minWidth: "250px" }} className="mt-5 w-25">
                <Card.Body>
                    <Card.Title className="text-center">
                        <img src={logo} alt="logo" className="mb-2 img-fluid" />
                        <br />
                        Difnificate</Card.Title>
                    {
                        loginError ?
                            <Alert variant="danger" className="my-2">
                                Usuario o contraseña incorrectos
                            </Alert>
                            :
                            <></>
                    }

                    <Form.Group className="mb-4" controlId="user">
                        <Form.Label>Usuario</Form.Label>
                        <Form.Control onChange={HandleChange} name="user" type="email" value={datosIngresados && datosIngresados.user} />
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="password">
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control onChange={HandleChange} name="password" type="password" value={datosIngresados && datosIngresados.password} />
                    </Form.Group>
                    <input className="btn w-100  bg-color-1 text-white mb-4" type="button" onClick={() => ClickLogin()} value="Ingresar"></input>

                </Card.Body>
            </Card>
        </>
    )
}
export default LoginComponent