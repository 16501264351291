import { useEffect } from "react"
import { Container } from "react-bootstrap"
import Footer from "../components/general/Footer"
import ListaSitios from "../components/general/ListaSitios"
import NavBar from "../components/general/NavBar"
import '../styles/Main.css'

const Main = () => {

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return (
        <>
            <NavBar />
            <div className="main-banner-image w-100  d-flex justify-content-center align-items-center">
                <div className="text-white">
                    <hr className="divider"/>
                    <h1>Difnificate</h1>
                    <hr className="divider"/>
                </div>
            </div>
            <span id = "beneficios"></span>
            <Container className=" my-5">
                <ListaSitios/>
            </Container>
            <Footer/>
        </>
    )
}

export default Main