import React from "react";
import fBLogo from "../../assets/fb.png"
import mailLogo from "../../assets/mail.png"
import tWLogo from "../../assets/tw.png"
const Footer = () => {
    return (
        <div id = "contactanos" className="bg-color-1 jumbotron py-4 text-white text-center">
            <h5>Difnificate</h5><br />
            <div>
                <a href="https://www.facebook.com/DIFMunicipalUruapan/" rel="noreferrer" target="_blank" className="card-text text-white">
                    <img src={fBLogo} className="m-2" />
                </a>
                <a href="https://twitter.com/GobiernoUruapan" rel="noreferrer" target="_blank" className="card-text text-white">
                    <img src={tWLogo} className="m-2" />
                </a>
                <a href="mailto:comunicaciondif@uruapan.gob.mx" className="card-text text-white">
                    <img src={mailLogo} className="m-2" />
                </a>
            </div><br />
            <p>Copyright © 2022 | Gobierno de Uruapan | Todos los Derechos Reservados</p>


        </div>
    )
}

export default Footer