const data = [
    {
        name: "Radiología MADERO",
        veneficio: "Desc %",
        dir: "Col. Centro, Madero #7 Int 1",
        location: "https://goo.gl/maps/QeLnYHK1Ff2aPq4i6"
    },
    {
        name: "Centro de Diagnostico Clínico",
        veneficio: "40%",
        dir: "Paseo Lázaro Cárdenas 2000",
        location: "https://goo.gl/maps/PPN3kx583Yi4G2wy8"
    },
    {
        name: "Urulab",
        veneficio: "50%",
        dir: "Plaza Galeana Local 2 Av. Américas",
        location: "https://g.page/UruLab?share"
    },
    {
        name: "Centro Diagnóstico Terranova",
        veneficio: "Desc %",
        dir:"Col. Magdalena, Gonzalez Ortega #66",
        location: "https://goo.gl/maps/c4358nacaJwe6Riu9"
    }
    ,
    {
        name: "Laboratorio La Magdalena",
        veneficio: "50%",
        dir:"Col. Centro, Prol. 20 de Noviembre #4",
        location: "https://goo.gl/maps/r3w44RuR4Et4ug5U9"
    },
    {
        name: "Diagnostico Integral",
        veneficio: "Desc %",
        dir:"Col. 1era Secc. Michoacán, Chiapas #401-B",
        location: "https://goo.gl/maps/7e6jFthA7XdC75Kt9"
    },
    {
        name: "Laboratorio Santa María",
        veneficio: "Desc%",
        dir:"Col. Magdalena, Priv Ignacio Zaragoza #16-A",
        location: "https://goo.gl/maps/X1sZ63C5QBunJvxVA"
    },
    {
        name: "RehCreate Rehabilitación & Spa",
        veneficio: "Desc%",
        dir:"Col. Lomas del Valle Sur, Paseo de la cima #717",
        location: "https://goo.gl/maps/wSgPxefW3VEf5NU79"
    },
    {
        name: "Optometrista Claudia Zetina",
        veneficio: "Desc%",
        dir:"Col. Ramón Farias, Yucatan #11",
        location: "https://goo.gl/maps/mj7rre5ihBumt3Gq7"
    },
    {
        name: "Neurocirujano Dr. Gutierrez Saavedra",
        veneficio: "$380",
        dir:"DIF MUNICIPAL",
        location: "https://goo.gl/maps/n8u53bzarPio8Ya57"
    },
    {
        name: "Traumatologo Dr. Rogelio Calderón",
        veneficio: "$250",
        dir:"DIF MUNICIPAL",
        location: "https://goo.gl/maps/n8u53bzarPio8Ya57"
    },
    {
        name: "Ginecología Dra. Ma Isabel Duarte",
        veneficio: "$250",
        dir:"DIF MUNICIPAL",
        location: "https://goo.gl/maps/n8u53bzarPio8Ya57"
    },
    {
        name: "Pediatría Dra. Mara Barbabosa Verdin",
        veneficio: "$250",
        dir:"DIF MUNICIPAL",
        location: "https://goo.gl/maps/n8u53bzarPio8Ya57"
    },
    {
        name: "Nutrición",
        veneficio: "$25",
        dir:"DIF MUNICIPAL",
        location: "https://goo.gl/maps/n8u53bzarPio8Ya57"
    },
    {
        name: "Psicología",
        veneficio: "$100",
        dir:"DIF MUNICIPAL",
        location: "https://goo.gl/maps/n8u53bzarPio8Ya57"
    },
    {
        name: "Tanatología",
        veneficio: "$120",
        dir:"DIF MUNICIPAL",
        location: "https://goo.gl/maps/n8u53bzarPio8Ya57"
    },
    {
        name: "Consulta Medica",
        veneficio: "$25",
        dir:"DIF MUNICIPAL",
        location: "https://goo.gl/maps/n8u53bzarPio8Ya57"
    },
    {
        name: "Consulta Dental",
        veneficio: "$20",
        dir:"DIF MUNICIPAL",
        location: "https://goo.gl/maps/n8u53bzarPio8Ya57"
    },
    {
        name: "Centro de Rehabilitación Integral C.R.I.",
        veneficio: "Desc%",
        dir:"DIF MUNICIPAL",
        location: "https://goo.gl/maps/n8u53bzarPio8Ya57"
    },
    {
        name: "Reumatologa Dra. Maria Arciga Barriga",
        veneficio: "$400",
        dir:"Col. Magdalena, Mazatlán #783",
        location: "https://goo.gl/maps/2JSs2NMrFrjYsrxx5"
    },
    {
        name: "Urologo Dr. Ulises Alejandre",
        veneficio: "$400",
        dir:"Col. Magdalena, Mazatlán #783",
        location: "https://goo.gl/maps/2JSs2NMrFrjYsrxx5"
    },
    {
        name: "Pediatría Dra. Maria Elena Madrigal",
        veneficio: "$200",
        dir:"Col. Obregón, Plaza Obregón #4",
        location: "https://goo.gl/maps/7zb2TwJX3RwLPo3p8"
    },
    {
        name: "Oftamologo Dr. Ricardo Moreno Mendoza",
        veneficio: "$400",
        dir:"Hospital Fray Juan",
        location: "https://goo.gl/maps/Y422f5Qz8rwSNM5e9"
    },
    {
        name: "Gastroenterologo Dr. Corona",
        veneficio: "$400",
        dir:"Hospital San Francisco",
        location: "https://goo.gl/maps/yQLkYJ8x3aivUKBQ9"
    }
]

export {data}