//curp regex
const curpformat = /^([A-Z&]|[a-z&]{1})([AEIOUX]|[aeioux]{1})([A-Z&]|[a-z&]{1})([A-Z&]|[a-z&]{1})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM]|[hm]{1})([AS|as|BC|bc|BS|bs|CC|cc|CS|cs|CH|ch|CL|cl|CM|cm|DF|df|DG|dg|GT|gt|GR|gr|HG|hg|JC|jc|MC|mc|MN|mn|MS|ms|NT|nt|NL|nl|OC|oc|PL|pl|QT|qt|QR|qr|SP|sp|SL|sl|SR|sr|TC|tc|TS|ts|TL|tl|VZ|vz|YN|yn|ZS|zs|NE|ne]{2})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([0-9]|[A-Z&]|[a-z&])[0-9]$/gm
const VerifiacarInputs = (itemSeleccionado) => {
    var inputError = ""
    inputError += itemSeleccionado.solicitante_name.length > 0 ? "" : "Nombre del solicitante\n"
    inputError += itemSeleccionado.beneficiario_name.length > 0 ? "" : "Nombre del beneficiario\n"
    inputError += itemSeleccionado.beneficiario_lastname_1.length > 0 ? "" : "Apellido paterno\n"
    inputError += itemSeleccionado.beneficiario_lastname_2.length > 0 ? "" : "Apellido materno\n"
    inputError += itemSeleccionado.colonia.length > 0 ? "" : "Colonia\n"
    inputError += itemSeleccionado.calle.length > 0 ? "" : "Calle\n"
    inputError += itemSeleccionado.domicilio_numero.length > 0 ? "" : "Número exterior\n"
    inputError += itemSeleccionado.born_date.length > 0 ? "" : "Fecha de nacimiento\n"
    inputError += itemSeleccionado.phone.length === 10 ? "" : "El teléfono debe contener 10 caracteres\n"

    if (itemSeleccionado.curp.length > 0) {
        if (!itemSeleccionado.curp.match(curpformat))
            inputError += "CURP no válido \n"
    }
    else
        inputError += "CURP\n"

    inputError += itemSeleccionado.seccion.length === 4 ? "" : "La sección debe contener 4 caracteres\n"
    inputError += itemSeleccionado.elector_key.length === 18 ? "" : "La clave de elector debe contener 18 caracteres\n"

    return inputError
}

export { VerifiacarInputs }