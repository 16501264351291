import React from "react";
import { Table } from 'react-bootstrap';
import { data } from "./Beneficios";
const ListaSitios = () => {



    return (
        <>
            <div className="shadow rounded-3 p-4 border">
                <div className="text-center mb-4">
                    <h4>Lista de beneficios</h4>
                </div>

                <Table bordered striped hover responsive variant="dark">
                    <thead>
                        <tr className="align-middle">
                            <th>SERVICIO</th>
                            <th>BENEFICIO</th>
                            <th>DIRECCIÓN</th>

                        </tr>
                    </thead>
                    <tbody className="align-middle">
                        {data.map((item, idx) => (
                            <tr className="table-light" key={idx}>
                                <td>{item.name}</td>
                                <td>{item.veneficio}</td>
                                <td>
                                    <p>{item.dir}</p>
                                    <a href={item.location} target="_blank" rel="noopener noreferrer">Ver Ubicación</a>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default ListaSitios