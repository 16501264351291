import { useContext, useEffect, useState } from "react"
import { doc, setDoc } from '@firebase/firestore';
import { db } from "../../Firebase/FirebaseConfig";
import Modal from "react-bootstrap/Modal";
import ScanBarCode from "./ScanBarCode";
import { AddNewCardNumber, GetCurrentCardNumber, sumarCardNumber } from "./ManageCardNumber";
import { VerifiacarInputs } from "../general/VerificarInputs";
import UserContext from "../../context/UserContext";

const SimpleAdmin = () => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const OpenCLoseModalInsertar = () => {
        setModalInsertar(!modalInsertar)
    }
    const HandleChange = e => {
        const { name, value } = e.target
        setitemSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(itemSeleccionado)
    }

    const [itemSeleccionado, setitemSeleccionado] = useState({
        solicitante_name: "",
        beneficiario_name: "",
        beneficiario_lastname_1: "",
        beneficiario_lastname_2: "",
        colonia: "",
        calle: "",
        domicilio_numero: "",
        curp: "",
        seccion: "",
        phone: "",
        house_phone: "",
        born_date: "",
        elector_key: "",
        card: ""
    })
    const [dBCardNumber, setDBCardNumber] = useState(undefined)
    useEffect(() => {
        GetCurrentCardNumber(setDBCardNumber)
    }, [])
    const { user } = useContext(UserContext)
    const PostUsuario = async () => {

        const userInputData = VerifiacarInputs(itemSeleccionado)
        if (userInputData === "") {

            await setDoc(doc(db, "users", itemSeleccionado.curp),
                {
                    solicitante_name: itemSeleccionado.solicitante_name,
                    beneficiario_name: itemSeleccionado.beneficiario_name,
                    beneficiario_lastname_1: itemSeleccionado.beneficiario_lastname_1,
                    beneficiario_lastname_2: itemSeleccionado.beneficiario_lastname_2,
                    colonia: itemSeleccionado.colonia,
                    calle: itemSeleccionado.calle,
                    domicilio_numero: itemSeleccionado.domicilio_numero,
                    curp: itemSeleccionado.curp,
                    seccion: itemSeleccionado.seccion,
                    phone: itemSeleccionado.phone,
                    house_phone: itemSeleccionado.house_phone,
                    born_date: itemSeleccionado.born_date,
                    elector_key: itemSeleccionado.elector_key,
                    card: user.data.cardNumberId  + dBCardNumber.company + dBCardNumber.product
                }).then(() => {
                    alert("¡Usuario creado correctamente!");
                })
                .catch(err => {
                    alert("¡Error al crear el usuario!, por favor contacta a tu administrador");
                });
            OpenCLoseModalInsertar()
            AddNewCardNumber(sumarCardNumber(dBCardNumber.product))
            GetCurrentCardNumber(setDBCardNumber)
        }
        else {
            // ObtenerCostoDeEnvio()
            alert("Verifica los siguientes campos:\n" + userInputData)
        }

    }
    return (
        <>

            <div className="container">

                <ScanBarCode />

                {/*<div className="bg-white rounded-3 text-center mb-5 border">
                    <h1>Usuarios<button className="btn text-white btn-primary m-5" onClick={() => OpenCLoseModalInsertar()}>Nuevo usuario</button></h1>
                </div>*/}
            </div>
            <Modal
                show={modalInsertar}
                onHide={() => OpenCLoseModalInsertar()}>
                <Modal.Header closeButton>Nuevo Usuario</Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="form-label">Nombre del solicitante</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="solicitante_name" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Nombre del beneficiario</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="beneficiario_name" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Apellido paterno</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="beneficiario_lastname_1" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Apellido materno</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="beneficiario_lastname_2" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Calle</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="calle" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Número exterior</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="domicilio_numero" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Colonia</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="colonia" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Fecha de nacimiento</label>
                        <br />
                        <input className="form-control mb-3" type="date" name="born_date" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Teléfono celular</label>
                        <br />
                        <input className="form-control mb-3" type="number" name="phone" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Teléfono de casa (opcional)</label>
                        <br />
                        <input className="form-control mb-3" type="number" name="house_phone" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">CURP</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="curp" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Sección</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="seccion" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Clave de elector</label>
                        <br />
                        <input className="form-control mb-3" type="text" name="elector_key" onChange={HandleChange}></input>
                        <br />
                        <label className="form-label">Tarjeta No.</label>
                        <br />
                        {dBCardNumber && <input className="form-control mb-3" disabled type="text" name="card" value={dBCardNumber.country.toString() + dBCardNumber.company + dBCardNumber.product}></input>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => PostUsuario()} className="btn btn-primary">Aceptar</button>
                    <button onClick={() => OpenCLoseModalInsertar()} className="btn btn-danger">Cancelar</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SimpleAdmin