import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore"
import { useContext, useEffect, useState } from "react"
import { Alert, Spinner } from "react-bootstrap"
import { db } from "../../Firebase/FirebaseConfig"
import { v4 as uuidv4 } from 'uuid';
import UserContext from '../../context/UserContext'
const ScanBarCode = () => {
    const status = ["esperando", "cargando", "error", "ok"]
    const [currentStatus, setCurrentStatus] = useState(status[0])
    const [userData, setUserData] = useState(undefined)
    useEffect(() => {

        var barcode = '';
        var interval;
        document.addEventListener('keydown', function (evt) {
            if (interval)
                clearInterval(interval);
            if (evt.code == 'Enter') {
                if (barcode)
                    handleBarcode(barcode);
                barcode = '';
                return;
            }
            if (evt.key != 'Shift')
                barcode += evt.key;
            interval = setInterval(() => barcode = '', 20);
        });

        function handleBarcode(scanned_barcode) {
            setCurrentStatus(status[1])
            //document.querySelector('#last-barcode').innerHTML = scanned_barcode;
            GetUser(scanned_barcode)
        }

    }, [])

    const GetUser = async (cardNumber) => {
        const citiesRef = collection(db, "users");
        const q = query(citiesRef, where("card", "==", cardNumber));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length > 0) {

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                setUserData(doc)
                SaveScanedCode(doc.data())
                // console.log(doc.data().name)

            });

            setCurrentStatus(status[3])

        } else {

            setCurrentStatus(status[2])

        }


    }
    const { user } = useContext(UserContext)
    const SaveScanedCode = async (data) => {
        const temID = uuidv4();
        
        var auxDate = new Date()
        var date = auxDate.getFullYear() + '-' + ("0" + (auxDate.getMonth() + 1)).slice(-2) + '-' + ("0" + auxDate.getDate()).slice(-2)
        await setDoc(doc(db, "movimientos", temID), {
            admin_name: user.data.name,
            action: user.data.action,
            solicitante_name: data.solicitante_name,
            beneficiario_name: data.beneficiario_name,
            beneficiario_lastname_1: data.beneficiario_lastname_1,
            beneficiario_lastname_2: data.beneficiario_lastname_2,
            curp: data.curp,
            card: data.card,
            date: date
        });
    }
    return (
        <>
            <div style={{ marginTop: "20vh" }} className=" py-5 bg-white rounded-3 text-center mb-5 border">
                {currentStatus === status[0] && <h3>Por favor escanea un código de barras...</h3>}
                {currentStatus === status[1] &&
                    <div>
                        <p>Codigo detectado: <span id="last-barcode"></span></p>
                        <div className="text-center">
                            <Spinner animation="border" variant="danger" />
                        </div>
                    </div>
                }
                {currentStatus === status[2] &&
                    <Alert className="mx-auto w-75" variant="danger">
                        Error: Código no encontrado
                    </Alert>}
                {currentStatus === status[3] &&
                    <Alert className="mx-auto w-75" variant="success">
                        <p>Se realizo {user.data.action} a:</p>
                        <p>{"Nombre del solicitante: " + userData.data().solicitante_name}</p>
                        <p>{"Nombre del beneficiario: " + userData.data().beneficiario_name + " " + userData.data().beneficiario_lastname_1 + " " + userData.data().beneficiario_lastname_2}</p>
                        <p>{"CURP: " + userData.data().curp}</p>
                        <p>{"Tarjeta No. " + userData.data().card}</p>
                    </Alert>}

            </div>

        </>
    )
}

export default ScanBarCode