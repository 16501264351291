import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner, Table } from "react-bootstrap";
import { db } from '../../Firebase/FirebaseConfig'
import { collection, getDocs,query,where , Timestamp} from '@firebase/firestore';
import 'table2excel';

const MovimientosCRUD = () => {
    const Table2Excel = window.Table2Excel;
    const [data, setData] = useState(undefined)

    //Metodo get para obtener los items de la DB
    const GetItems = async () => {
        document.getElementById("showMovimientosBtn").hidden = true
        const querySnapshot = await getDocs(collection(db, "movimientos"));
        setData(querySnapshot.docs)
    }

    // se activa en el onclick de los botones eliminar y editar abriendo el modal
    //correspondiente
    const ExportToExcel = () => {
        const table2excel = new Table2Excel();
        table2excel.export(document.querySelectorAll("#table"));
    }
    const [itemSeleccionado, setitemSeleccionado] = useState({
        de: "",
        hasta: ""
    })
    const HandleChange = e => {
        const { name, value } = e.target
        setitemSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(itemSeleccionado)
    }
    // const Filtrar = async () => {
    //     const citiesRef = collection(db, "movimientos");
        
    //     // "22-1-2022"
    //     // 2022-01-22
    //     console.log(itemSeleccionado.de);
    //     const q = query(citiesRef,  where("date", "==",itemSeleccionado.de));
    //     // , where("name", "==", "Denver")
    //     const querySnapshot = await getDocs(q);
    //     if (querySnapshot.docs.length > 0) {

    //             setData(querySnapshot.docs)
               
    //     }
    //     else alert("No se encontraron movimientos en este periodo de tiempo")

    // }
    // <Container>
    //                 <Row xs={1} md={4} className="g-4 mb-3">
    //                     <Col>
    //                         <label className="form-label">De </label>
    //                     </Col>
    //                     <Col>
    //                         <input className="form-control" type="date" name="de" onChange={HandleChange}></input>
    //                     </Col>

    //                     <Col>
    //                         <label className="form-label">Hasta </label>
    //                     </Col>
    //                     <Col>
    //                         <input className="form-control" type="date" name="hasta" onChange={HandleChange}></input>
    //                     </Col>
    //                 </Row>
    //                 <button id="showMovimientosBtn" className="btn text-white btn-secondary m-5" onClick={() => Filtrar()}>Filtrar</button>
    //             </Container>
    return (
        <div className="container">

            <div className="bg-white rounded-3 text-center my-5 border">
                <h1 className="m-5">Movimientos</h1>
                <button id="showMovimientosBtn" className="btn text-white bg-color-1 m-5" onClick={() => GetItems()}>Ver movimientos</button>
                {data !== undefined ?
                    <>


                        <Table className="" id="table" striped bordered hover responsive>
                            <thead>
                                <tr className="align-middle">
                                    <th>Administrador</th>
                                    <th>Acción</th>
                                    <th>Nombre del solicitante</th>
                                    <th>Nombre del beneficiario</th>
                                    <th>Apellido paterno</th>
                                    <th>Apellido materno</th>
                                    <th>Fecha</th>
                                    <th>Tarjeta No.</th>
                                </tr>
                            </thead>
                            <tbody className="align-middle">
                                {data.map((item, idx) => (
                                    <tr key={"user" + idx}>
                                        <td>{item.data().admin_name}</td>
                                        <td>{item.data().action}</td>
                                        <td>{item.data().solicitante_name}</td>
                                        <td>{item.data().beneficiario_name}</td>
                                        <td>{item.data().beneficiario_lastname_1}</td>
                                        <td>{item.data().beneficiario_lastname_2}</td>
                                        <td> <input className="bg-transparent border-0 text-center" type="text" readOnly value={item.data().date} /></td>
                                        <td><input className="bg-transparent border-0 text-center" type="text" readOnly value={item.data().card} /></td>
                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                        <div>
                            <input onClick={() => ExportToExcel()} type="button" className="btn btn-success my-4" value="Descargar tabla" />
                        </div>
                    </> :
                    <>
                        <div className="text-center mt-4">
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default MovimientosCRUD