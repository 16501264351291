import './styles/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserState } from "./context/UserState";
import Main from './pages/Main';
import Admin from './pages/Admin';
import AddAdmin from './components/extraPages/AddAdmin';
function App() {
  return (
    <Router>
      <UserState>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/add-admin" element={<AddAdmin />} />
        </Routes>
      </UserState>
    </Router>
  );
}

export default App;
