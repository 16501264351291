import React, { useContext, useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal'
import { db } from '../../Firebase/FirebaseConfig'
import { collection, deleteDoc, updateDoc, doc, getDocs, setDoc } from '@firebase/firestore';
import 'table2excel';
import { AddNewCardNumber, GetCurrentCardNumber, sumarCardNumber } from "./ManageCardNumber";
import { VerifiacarInputs } from '../general/VerificarInputs'
import ScanBarCode from "./ScanBarCode";
import MovimientosCRUD from "./MoviminetosCRUD";
import UserContext from "../../context/UserContext";

const SuperAdmin = () => {
    const Table2Excel = window.Table2Excel;
    const [data, setData] = useState(undefined)
    //manejadores para mostrar y ocultar los modales
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [modalEliminar, setModalEliminar] = useState(false);
    //objeto para identificar con cual item de la lista estamos
    //trabajando de manera especifica
    const [itemSeleccionado, setitemSeleccionado] = useState({
        solicitante_name: "",
        beneficiario_name: "",
        beneficiario_lastname_1: "",
        beneficiario_lastname_2: "",
        colonia: "",
        calle: "",
        domicilio_numero: "",
        curp: "",
        seccion: "",
        phone: "",
        house_phone: "",
        born_date: "",
        elector_key: "",
        card: ""
    })
    const [editItemSeleccionado, setEditItemSeleccionado] = useState(undefined)
    const [dBCardNumber, setDBCardNumber] = useState(undefined)
    //se activan en el on click de los botones
    //insertar, eliminar, editar correspondientemente
    const OpenCLoseModalInsertar = () => {
        setModalInsertar(!modalInsertar)
    }
    const OpenCLoseModalEliminar = () => {
        setModalEliminar(!modalEliminar)
    }
    const OpenCLoseModalEditar = () => {
        setModalEditar(!modalEditar)

    }
    const HandleChange = e => {
        const { name, value } = e.target
        setitemSeleccionado((prevState) => ({
            ...prevState,
            [name]: value
        }))
        //console.log(itemSeleccionado)
    }

    //Metodo get para obtener los items de la DB
    const GetItems = async () => {
        document.getElementById("showUsersBtn").hidden = true
        const querySnapshot = await getDocs(collection(db, "users"));
        setData(querySnapshot.docs)
    }

    useEffect(() => {
        GetCurrentCardNumber(setDBCardNumber)
    }, [])
    const { user } = useContext(UserContext)
    const PostUsuario = async () => {

        const userInputData = VerifiacarInputs(itemSeleccionado)
        if (userInputData === "") {

            await setDoc(doc(db, "users", itemSeleccionado.curp),
                {
                    solicitante_name: itemSeleccionado.solicitante_name,
                    beneficiario_name: itemSeleccionado.beneficiario_name,
                    beneficiario_lastname_1: itemSeleccionado.beneficiario_lastname_1,
                    beneficiario_lastname_2: itemSeleccionado.beneficiario_lastname_2,
                    colonia: itemSeleccionado.colonia,
                    calle: itemSeleccionado.calle,
                    domicilio_numero: itemSeleccionado.domicilio_numero,
                    curp: itemSeleccionado.curp,
                    seccion: itemSeleccionado.seccion,
                    phone: itemSeleccionado.phone,
                    house_phone: itemSeleccionado.house_phone,
                    born_date: itemSeleccionado.born_date,
                    elector_key: itemSeleccionado.elector_key,
                    card: user.data.cardNumberId + dBCardNumber.company + dBCardNumber.product
                }).then(() => {
                    alert("¡Usuario creado correctamente!");
                })
                .catch(err => {
                    alert("¡Error al crear el usuario!, por favor contacta a tu administrador");
                });
            GetItems()
            OpenCLoseModalInsertar()
            AddNewCardNumber(sumarCardNumber(dBCardNumber.product))
            GetCurrentCardNumber(setDBCardNumber)

            alert("¡Usuario creado correctamente!");
        }
        else {
            // ObtenerCostoDeEnvio()
            alert("Verifica los siguientes campos:\n" + userInputData)
        }

    }
    const EditUser = async () => {
        const ref = doc(db, "users", itemSeleccionado.curp);
        const userInputData = VerifiacarInputs(itemSeleccionado)
        if (userInputData === "") {

            await updateDoc(ref, {
                solicitante_name: itemSeleccionado.solicitante_name,
                beneficiario_name: itemSeleccionado.beneficiario_name,
                beneficiario_lastname_1: itemSeleccionado.beneficiario_lastname_1,
                beneficiario_lastname_2: itemSeleccionado.beneficiario_lastname_2,
                colonia: itemSeleccionado.colonia,
                calle: itemSeleccionado.calle,
                domicilio_numero: itemSeleccionado.domicilio_numero,
                curp: itemSeleccionado.curp,
                seccion: itemSeleccionado.seccion,
                phone: itemSeleccionado.phone,
                house_phone: itemSeleccionado.house_phone,
                born_date: itemSeleccionado.born_date,
                elector_key: itemSeleccionado.elector_key,

            });

            GetItems()
            OpenCLoseModalEditar()
            alert("¡Usuario editado correctamente!");
        } else {
            // ObtenerCostoDeEnvio()
            alert("Verifica los siguientes campos:\n" + userInputData)
        }
    }
    const DeleteUser = async () => {
        await deleteDoc(doc(db, "users", editItemSeleccionado.data().curp));
        GetItems()
        OpenCLoseModalEliminar()
        alert("¡Usuario eliminado correctamente!")

    }

    // se activa en el onclick de los botones eliminar y editar abriendo el modal
    //correspondiente
    const SeleccionarUsuario = (item, caso) => {
        setitemSeleccionado(item.data());
        setEditItemSeleccionado(item);

        (caso === "editar") ?
            OpenCLoseModalEditar()
            :
            OpenCLoseModalEliminar()
    }

    const ExportToExcel = () => {
        const table2excel = new Table2Excel();
        table2excel.export(document.querySelectorAll("#table"));
    }
    return (
        <div className="container">

            <ScanBarCode />

            <div className="bg-white rounded-3 text-center my-5 border">
                <h1>Usuarios<button className="btn text-white btn-primary m-5" onClick={() => OpenCLoseModalInsertar()}>Nuevo usuario</button></h1>
                <button id="showUsersBtn" className="btn text-white bg-color-1 m-5" onClick={() => GetItems()}>Ver usuarios</button>
                <Modal
                    show={modalInsertar}
                    onHide={() => OpenCLoseModalInsertar()}>
                    <Modal.Header closeButton>Nuevo Usuario</Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label className="form-label">Nombre del solicitante</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="solicitante_name" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Nombre del beneficiario</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="beneficiario_name" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Apellido paterno</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="beneficiario_lastname_1" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Apellido materno</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="beneficiario_lastname_2" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Calle</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="calle" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Número exterior</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="domicilio_numero" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Colonia</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="colonia" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Fecha de nacimiento</label>
                            <br />
                            <input className="form-control mb-3" type="date" name="born_date" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Teléfono celular</label>
                            <br />
                            <input className="form-control mb-3" type="number" name="phone" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Teléfono de casa (opcional)</label>
                            <br />
                            <input className="form-control mb-3" type="number" name="house_phone" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">CURP</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="curp" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Sección</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="seccion" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Clave de elector</label>
                            <br />
                            <input className="form-control mb-3" type="text" name="elector_key" onChange={HandleChange}></input>
                            <br />
                            <label className="form-label">Tarjeta No.</label>
                            <br />
                            {dBCardNumber && <input className="form-control mb-3" disabled type="text" name="card" value={dBCardNumber.country.toString() + dBCardNumber.company + dBCardNumber.product}></input>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={() => PostUsuario()} className="btn btn-primary">Aceptar</button>
                        <button onClick={() => OpenCLoseModalInsertar()} className="btn btn-danger">Cancelar</button>
                    </Modal.Footer>
                </Modal>
                {data !== undefined ?
                    <>
                        <Table className="" id="table" striped bordered hover responsive>
                            <thead>
                                <tr className="align-middle">
                                    <th>Nombre del solicitante</th>
                                    <th>Nombre del beneficiario</th>
                                    <th>Apellido paterno</th>
                                    <th>Apellido materno</th>
                                    <th>Calle</th>
                                    <th>Número exterior</th>
                                    <th>Colonia</th>
                                    <th>Fecha de nacimiento</th>
                                    <th>Teléfono</th>
                                    <th>Teléfono de casa</th>
                                    <th>CURP</th>
                                    <th>Sección</th>
                                    <th>Clave de elector</th>
                                    <th>Tarjeta No.</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody className="align-middle">
                                {data.map((item, idx) => (
                                    <tr key={"user" + idx}>
                                        <td>{item.data().solicitante_name}</td>
                                        <td>{item.data().beneficiario_name}</td>
                                        <td>{item.data().beneficiario_lastname_1}</td>
                                        <td>{item.data().beneficiario_lastname_2}</td>
                                        <td>{item.data().calle}</td>
                                        <td> <input className="bg-transparent border-0 text-center" type="text" readOnly value={item.data().domicilio_numero} /></td>
                                        <td>{item.data().colonia}</td>
                                        <td> <input className="bg-transparent border-0 text-center" type="text" readOnly value={item.data().born_date} /></td>
                                        <td> <input className="bg-transparent border-0 text-center" type="text" readOnly value={item.data().phone} /></td>
                                        <td> <input className="bg-transparent border-0 text-center" type="text" readOnly value={item.data().house_phone} /></td>
                                        <td>{item.data().curp}</td>
                                        <td><input className="bg-transparent border-0 text-center" type="text" readOnly value={item.data().seccion} /></td>
                                        <td>{item.data().elector_key}</td>
                                        <td><input className="bg-transparent border-0 text-center" type="text" readOnly value={item.data().card} /></td>
                                        <td>
                                            <button className="btn btn-primary m-1" onClick={() => SeleccionarUsuario(item, "editar")}>Editar</button>
                                            <button className="btn btn-danger" onClick={() => SeleccionarUsuario(item, "eliminar")}>Eliminar</button>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                        <div>
                            <input onClick={() => ExportToExcel()} type="button" className="btn btn-success my-4" value="Descargar tabla" />
                        </div>
                        <Modal
                            show={modalEditar}
                            onHide={() => OpenCLoseModalEditar()}>
                            <Modal.Header closeButton>Editar usuario</Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <label className="form-label">Nombre del solicitante</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.solicitante_name} className="form-control mb-3" type="text" name="solicitante_name" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Nombre del beneficiario</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.beneficiario_name} className="form-control mb-3" type="text" name="beneficiario_name" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Apellido paterno</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.beneficiario_lastname_1} className="form-control mb-3" type="text" name="beneficiario_lastname_1" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Apellido materno</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.beneficiario_lastname_2} className="form-control mb-3" type="text" name="beneficiario_lastname_2" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Calle</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.calle} className="form-control mb-3" type="text" name="calle" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Número exterior</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.domicilio_numero} className="form-control mb-3" type="text" name="domicilio_numero" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Colonia</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.colonia} className="form-control mb-3" type="text" name="colonia" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Fecha de nacimiento</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.born_date} className="form-control mb-3" type="date" name="born_date" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Teléfono celular</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.phone} className="form-control mb-3" type="number" name="phone" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Teléfono de casa (opcional)</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.house_phone} className="form-control mb-3" type="number" name="house_phone" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">CURP</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.curp} className="form-control mb-3" type="text" name="curp" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Sección</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.seccion} className="form-control mb-3" type="text" name="seccion" onChange={HandleChange}></input>
                                    <br />
                                    <label className="form-label">Clave de elector</label>
                                    <br />
                                    <input value={itemSeleccionado && itemSeleccionado.elector_key} className="form-control mb-3" type="text" name="elector_key" onChange={HandleChange}></input>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" onClick={() => EditUser()} className="btn btn-primary">Editar</button>
                                <button onClick={() => OpenCLoseModalEditar()} className="btn btn-danger">Cancelar</button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={modalEliminar}
                            onHide={() => OpenCLoseModalEliminar()}>
                            <Modal.Header closeButton>Eliminar usuario</Modal.Header>
                            <Modal.Body>
                                <div className="text-center">
                                    <p>¿Estás seguro que deseas eliminar el usuario {editItemSeleccionado && editItemSeleccionado.data().name}?</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button onClick={() => DeleteUser()} className="btn btn-danger">Sí</button>
                                <button onClick={() => OpenCLoseModalEliminar()} className="btn btn-primary">No</button>
                            </Modal.Footer>
                        </Modal>

                    </> :
                    <>
                        <div className="text-center my-4">

                        </div>
                    </>
                }

            </div>

            <MovimientosCRUD />

        </div>
    )
}

export default SuperAdmin