import { useContext } from "react"
import LoginComponent from "../components/admin/LoginComponent"
import MiddleAdmin from "../components/admin/MiddleAdmin"
import SimpleAdmin from "../components/admin/SimpleAdmin"
import SuperAdmin from "../components/admin/SuperAdmin"
import NavBar from "../components/general/NavBar"
import UserContext from "../context/UserContext"

const Admin = () => {

    const { user } = useContext(UserContext)

    return (
        <>
            <NavBar />

            {user === undefined ?
                <div className="main-color-image">
                    <div className="main-banner-image w-100  d-flex justify-content-center align-items-center">
                        <LoginComponent />
                    </div>
                </div>
                :
                <div className="main-color-image">
                    <div className="main-banner-image w-100  d-flex justify-content-center align-items-center">

                        {user.data.type === "super" && <SuperAdmin />}
                        {user.data.type === "simple" && <SimpleAdmin />}
                        {user.data.type === "middle" && <MiddleAdmin />}
                    </div>
                </div>
            }
        </>
    )
}

export default Admin