import { auth, app, db } from "../Firebase/FirebaseConfig";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import UserContext from "./UserContext";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { doc, getDoc } from "firebase/firestore";


//Componente de alto orden que nos ayuda a manejar el contexto del
// sitio y alamcena los estados y variables con que puede interactuar
// un lider

export const UserState = (props) => {

    // coockie local del usuario
    const [cookies, setCookies, removeCookie] = useCookies(['admin'])
    //estados para manejar las cookies
    const [state, setState] = useState(cookies.admin)
    //variables para controlar el login
    const [loginError, setLoginError] = useState(false);

    // check user and password
    const Login = async (email, password) => {
        setLoginError(false)
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                GetLoginData(user)

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                //alert(errorCode + " " + errorMessage);
                //cookie.remove('admin');
                setState(undefined)
                removeCookie('admin', { path: "/" });
                setLoginError(true);

            });
    }

    //get user data from db
    const GetLoginData = async (user) => {

        const docRef = doc(db, "admins", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());

            setState({ mail: user.email, id: user.uid, data: docSnap.data() })
            setCookies("admin", { mail: user.email, id: user.uid, data: docSnap.data() }, { path: "/" })
        } else {
            // doc.data() will be undefined in this case
            alert("Error-002: por favor contacta a tu administrador");
        }

    }


    const Logout = () => {
        signOut(auth).then(() => {
            //   cookie.remove('admin');
            removeCookie('admin', { path: "/" });
            console.log("admin logout ");
            setState(undefined)

        }).catch((error) => {
            window.location.reload()

        });
    }
    return (
        <UserContext.Provider value={{
            user: state,
            auth,
            Login,
            loginError,
            Logout,

            cookies
        }}>
            {props.children}
        </UserContext.Provider>
    )

}